import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { Queue } from './types';
import { listOrganizationQueues } from './api';
// eslint-disable-next-line import/no-cycle
import { AppState } from '.';

const organizationAdapter = createEntityAdapter<Queue>();

export const organizationQueues = createSlice({
  name: 'organizationQueues',
  initialState: organizationAdapter.getInitialState(),
  reducers: {},
  extraReducers: {
    [listOrganizationQueues.fulfilled.type]: (state, action) => {
      organizationAdapter.upsertMany(state, Object.values(action.payload.queues));
    },
  },
});

export const { selectAll: selectOrganizationQueues } = organizationAdapter.getSelectors((state: AppState) => {
  return state.organizationQueues;
});
export default organizationQueues.reducer;
