import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import initFirebase from './init-firebase';
import Organization from './containers/organization';
import AuthCallback from './containers/auth-callback';
import LogoutCallback from './containers/logout-callback';

initFirebase();

export default function App() {
  return (
    <Router>
      <Suspense fallback={<div />}>
        <Switch>
          <Route exact path="/auth-callback" component={AuthCallback} />
          <Route exact path="/logout-callback" component={LogoutCallback} />
          <Route path="/:organization" component={Organization} />
        </Switch>
      </Suspense>
    </Router>
  );
}
