import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useToken from '../common/use-token';
import manager from '../common/auth-manager';

export default function AuthCallback() {
  const history = useHistory();
  const [, setToken] = useToken();

  useEffect(() => {
    manager.signinRedirectCallback().then((user) => {
      const { queue, organization, from } = user.state;
      setToken(user.id_token);
      history.push(from || `/${organization}/queues/${queue}/admin`);
    });
  }, [setToken, history]);

  return <></>;
}
