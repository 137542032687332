import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import manager from '../common/auth-manager';

export default function LogoutCallback() {
  const history = useHistory();

  useEffect(() => {
    manager.signoutRedirectCallback().then((response) => {
      const { queue, organization, from } = response.state;

      history.push(from || `/${organization}/queues/${queue}/admin`);
    });
  }, [history]);

  return <></>;
}
