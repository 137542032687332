import { useState } from 'react';
import useGridMobileEventListener, { GridMobileEventType } from './use-grid-mobile-event-listener';

const useGridMobileInstallationResumedListener = (handler?: () => void) => {
  const [lastGridMobileResumedTimestamp, seLastGridMobileResumedTimestamp] = useState(0);

  useGridMobileEventListener(GridMobileEventType.InstallationResumed, () => {
    seLastGridMobileResumedTimestamp(Date.now());
    if (handler) {
      handler();
    }
  });

  return { lastGridMobileResumedTimestamp };
};

export default useGridMobileInstallationResumedListener;
