import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { transferPositions } from './api';
import { Position } from './types';

interface PositionState {
  selectedPendingPositionIds: string[];
  selectedNotifiedPositionIds: string[];
  selectedFulfilledPositionIds: string[];
  transferredPositions: Position[];
}

const positionsAdapter = createEntityAdapter<Position>();

const initialState = positionsAdapter.getInitialState({
  selectedPendingPositionIds: [],
  selectedNotifiedPositionIds: [],
  selectedFulfilledPositionIds: [],
  transferredPositions: [],
});

const positionTransferSlice = createSlice({
  name: 'positions-transfer',
  initialState,
  reducers: {
    selectPendingPosition: (state: PositionState, action) => {
      const positionId = action.payload;
      if (!state.selectedPendingPositionIds.includes(positionId)) {
        state.selectedPendingPositionIds.push(positionId);
      }
    },
    deselectPendingPosition: (state, action) => {
      const positionId = action.payload;
      const filteredPositions = state.selectedPendingPositionIds.filter((id) => id !== positionId);
      return {
        ...state,
        selectedPendingPositionIds: filteredPositions,
      };
    },
    selectAllPendingPositions: (state, action) => {
      return {
        ...state,
        selectedPendingPositionIds: action.payload,
      };
    },
    clearSelectedPendingPositions: (state) => {
      return {
        ...state,
        selectedPendingPositionIds: [],
      };
    },

    selectNotifiedPosition: (state: PositionState, action) => {
      const positionId = action.payload;
      if (!state.selectedNotifiedPositionIds.includes(positionId)) {
        state.selectedNotifiedPositionIds.push(positionId);
      }
    },
    deselectNotifiedPosition: (state, action) => {
      const positionId = action.payload;
      const filteredPositions = state.selectedNotifiedPositionIds.filter((id) => id !== positionId);
      return {
        ...state,
        selectedNotifiedPositionIds: filteredPositions,
      };
    },
    selectAllNotifiedPositions: (state, action) => {
      return {
        ...state,
        selectedNotifiedPositionIds: action.payload,
      };
    },
    clearSelectedNotifiedPositions: (state) => {
      return {
        ...state,
        selectedNotifiedPositionIds: [],
      };
    },

    selectFulfilledPosition: (state: PositionState, action) => {
      const positionId = action.payload;
      if (!state.selectedFulfilledPositionIds.includes(positionId)) {
        state.selectedFulfilledPositionIds.push(positionId);
      }
    },
    deselectFulfilledPosition: (state, action) => {
      const positionId = action.payload;
      const selectedFulfilledPositionIds = state.selectedFulfilledPositionIds.filter((id) => id !== positionId);
      return {
        ...state,
        selectedFulfilledPositionIds,
      };
    },
    selectAllFulfilledPositions: (state, action) => {
      return {
        ...state,
        selectedFulfilledPositionIds: action.payload,
      };
    },
    clearSelectedFulfilledPositions: (state) => {
      return {
        ...state,
        selectedFulfilledPositionIds: [],
      };
    },
    filterOutSelectedPositions: (state, action) => {
      const positionIds: string[] = action.payload;
      const selectedFulfilledPositionIds = state.selectedFulfilledPositionIds.filter((id) => !positionIds.includes(id));
      const selectedNotifiedPositionIds = state.selectedNotifiedPositionIds.filter((id) => !positionIds.includes(id));
      const selectedPendingPositionIds = state.selectedPendingPositionIds.filter((id) => !positionIds.includes(id));
      return {
        ...state,
        selectedFulfilledPositionIds,
        selectedNotifiedPositionIds,
        selectedPendingPositionIds,
      };
    },
  },
  extraReducers: {
    [transferPositions.fulfilled.type]: (state, action) => {
      positionsAdapter.upsertMany(state, action.payload.positions);
    },
  },
});

export const {
  selectPendingPosition,
  deselectPendingPosition,
  clearSelectedPendingPositions,
  selectAllPendingPositions,
  selectNotifiedPosition,
  deselectNotifiedPosition,
  clearSelectedNotifiedPositions,
  selectAllNotifiedPositions,
  selectFulfilledPosition,
  deselectFulfilledPosition,
  clearSelectedFulfilledPositions,
  selectAllFulfilledPositions,
  filterOutSelectedPositions,
} = positionTransferSlice.actions;

export const getAllSelectedPositionIds = (state: any) => ({
  pending: state.positionsTransfer.selectedPendingPositionIds,
  notified: state.positionsTransfer.selectedNotifiedPositionIds,
  fulfilled: state.positionsTransfer.selectedFulfilledPositionIds,
});

export default positionTransferSlice.reducer;
