import { createEntityAdapter, createSlice, createSelector, Dictionary, EntityId } from '@reduxjs/toolkit';
import { Position, PositionStatus } from './types';
// eslint-disable-next-line import/no-cycle
import { AppState, setOne } from './index';
import {
  activatePosition,
  callPosition,
  cancelPositionByAdmin,
  checkInPosition,
  completePosition,
  createPosition,
  loadClickAndCollect,
  loadPosition,
  loadPositions,
  nextPosition,
  updateNotificationForPosition,
  cancelPositionByUser,
  updatePositionNumberOfPeople,
  createDigitalCallPosition,
  loadDigitalCallPosition,
  submitFeedbackForm,
  assignManagerForBooking,
  assignManagerForPosition,
  unAssignManagerFromPosition,
  removeDigitalCallPositionFromStore,
  checkOutOldestPosition,
  checkOutPosition,
  rejectPosition,
  retryPosition,
  loadPositionBySession,
  createClickAndCollectOrder,
  getClickAndCollectOrders,
} from './api';

const positionsAdapter = createEntityAdapter<Position>();

const setOnePosition = (state: any, item: Position) => {
  return setOne<Position>(positionsAdapter, state, item, item.id);
};

export const positions = createSlice({
  name: 'positions',
  initialState: positionsAdapter.getInitialState(),
  reducers: {},
  extraReducers: {
    [loadPositions.pending.type]: (state) => ({
      ...state,
    }),
    [loadPositions.fulfilled.type]: (
      state,
      action: { payload: { data: { positions: undefined | { [id: string]: Position } }; isReplaceExisting: boolean } },
    ) => {
      // if it's not socket - we just got all positions and need to sync them as they are
      const incomingPositions = action.payload.data.positions;
      if (action.payload.isReplaceExisting) {
        // if there is no positions - no tickets in the queue
        const positionsList = Object.entries<Position>(incomingPositions ?? {}).map(([, position]) => position);
        positionsAdapter.setAll(state, positionsList);
        return;
      }
      // if it's socket - we should update positions which we got but not the rest
      if (!incomingPositions) return;

      const { selectById } = positionsAdapter.getSelectors();

      const entries = Object.entries<Position>(incomingPositions);

      entries
        .filter(([id, position]) => {
          const currentPosition = selectById(state, id);

          if (!currentPosition) return true;

          // eslint-disable-next-line no-underscore-dangle
          if (!position._ts || !currentPosition._ts) return true;

          // eslint-disable-next-line no-underscore-dangle
          const result = position._ts >= currentPosition._ts;

          return result;
        })
        .forEach(([, position]) => setOnePosition(state, position));
    },
    [loadClickAndCollect.fulfilled.type]: (state, action) => {
      if (action.payload.positions) {
        positionsAdapter.upsertMany(state, action.payload.positions);
      }
    },
    [loadPosition.fulfilled.type]: (state, action) => {
      if (action.payload.positions) {
        Object.entries(action.payload.positions).forEach(([, item]) => {
          setOnePosition(state, item as Position);
        });
      }
    },
    [activatePosition.fulfilled.type]: (state, action) => {
      if (action.payload.positions) {
        positionsAdapter.upsertMany(state, action.payload.positions);
      }
    },
    [completePosition.fulfilled.type]: (state, action) => {
      if (action.payload.positions) {
        positionsAdapter.upsertMany(state, action.payload.positions);
      }
    },
    [rejectPosition.fulfilled.type]: (state, action) => {
      if (action.payload.positions) {
        positionsAdapter.upsertMany(state, action.payload.positions);
      }
    },
    [cancelPositionByAdmin.fulfilled.type]: (state, action) => {
      if (action.payload.positions) {
        positionsAdapter.removeMany(state, Object.keys(action.payload.positions));
      }
    },
    [createPosition.fulfilled.type]: (state, action) => {
      if (action.payload.positions) {
        positionsAdapter.upsertMany(state, action.payload.positions);
      }
    },
    [createDigitalCallPosition.fulfilled.type]: (state, action) => {
      if (action.payload.positions) {
        const firstItemKey = Object.keys(action.payload.positions)[0];
        positionsAdapter.upsertOne(state, action.payload.positions[firstItemKey]);
      }
    },
    [nextPosition.fulfilled.type]: (state, action) => {
      if (action.payload.positions) {
        positionsAdapter.upsertMany(state, action.payload.positions);
      }
    },
    [callPosition.fulfilled.type]: (state, action) => {
      if (action.payload.positions) {
        positionsAdapter.upsertMany(state, action.payload.positions);
      }
    },
    [retryPosition.fulfilled.type]: (state, action) => {
      if (action.payload.positions) {
        const firstItemKey = Object.keys(action.payload.positions)[0];
        const firstPosition = action.payload.positions[firstItemKey];
        positionsAdapter.updateOne(state, {
          id: firstItemKey,
          changes: { ...firstPosition, servedBy: firstPosition.servedBy ?? undefined },
        });
      }
    },
    [updateNotificationForPosition.fulfilled.type]: (state, action) => {
      if (action.payload.positions) {
        positionsAdapter.upsertMany(state, action.payload.positions);
      }
    },
    [checkInPosition.fulfilled.type]: (state, action) => {
      if (action.payload.positions) {
        positionsAdapter.upsertMany(state, action.payload.positions);
      }
    },
    [checkOutOldestPosition.fulfilled.type]: (state, action) => {
      if (action.payload.positions) {
        positionsAdapter.upsertMany(state, action.payload.positions);
      }
    },
    [cancelPositionByUser.fulfilled.type]: (state, action) => {
      if (action.payload.positions) {
        positionsAdapter.upsertMany(state, action.payload.positions);
      }
    },
    [updatePositionNumberOfPeople.fulfilled.type]: (state, action) => {
      if (action.payload.positions) {
        positionsAdapter.upsertMany(state, action.payload.positions);
      }
    },
    [checkOutPosition.fulfilled.type]: (state, action) => {
      if (action.payload.positions) {
        positionsAdapter.upsertMany(state, action.payload.positions);
      }
    },
    [loadDigitalCallPosition.fulfilled.type]: (state, action) => {
      if (action.payload.positions) {
        positionsAdapter.upsertMany(state, action.payload.positions);
      }
    },
    [removeDigitalCallPositionFromStore.fulfilled.type]: (state, action) => {
      if (action.payload.positions) {
        positionsAdapter.removeOne(state, Object.keys(action.payload.positions)[0]);
      }
    },
    [submitFeedbackForm.fulfilled.type]: (state, action) => {
      if (action.payload.positions) {
        positionsAdapter.upsertMany(state, action.payload.positions);
      }
    },
    [assignManagerForBooking.fulfilled.type]: (state, action) => {
      if (action.payload.positions) {
        positionsAdapter.upsertMany(state, action.payload.positions);
      }
    },
    [assignManagerForPosition.fulfilled.type]: (state, action) => {
      if (action.payload.positions) {
        positionsAdapter.upsertMany(state, action.payload.positions);
      }
    },
    [unAssignManagerFromPosition.fulfilled.type]: (state, action) => {
      if (action.payload.positions) {
        const firstPositionId = Object.keys(action.payload.positions)[0];
        const firstPosition = action.payload.positions[firstPositionId];

        positionsAdapter.updateOne(state, { id: firstPositionId, changes: { ...firstPosition, servedBy: undefined } });
      }
    },
    [loadPositionBySession.fulfilled.type]: (state, action) => {
      if (action.payload.positions) {
        positionsAdapter.upsertMany(state, action.payload.positions);
      }
    },
    [createClickAndCollectOrder.fulfilled.type]: (state, action) => {
      if (action.payload.positions) {
        positionsAdapter.upsertMany(state, action.payload.positions);
      }
    },
    [getClickAndCollectOrders.fulfilled.type]: (state, action) => {
      if (action.payload.positions) {
        positionsAdapter.upsertMany(state, action.payload.positions);
      }
    },
  },
});

export const { selectById: selectPositionById } = positionsAdapter.getSelectors();

export const selectClickAndCollectPositions = (state: AppState, queue: string) =>
  (state.positions.ids as string[]).reduce((acc, id: string) => {
    const position = state.positions.entities[id];
    if (
      position &&
      position.queue === queue &&
      (position.type === 'storePickup' || position.type === 'curbsidePickup')
    ) {
      acc.push(position);
    }
    return acc;
  }, [] as Position[]);

const positionsIdsSelector = (state: AppState) => state.positions.ids;
const positionsEntitiesSelector = (state: AppState) => state.positions.entities;

const positionsCombiner = (positionStatus: PositionStatus) => (ids: EntityId[], entities: Dictionary<Position>) => {
  const result = ids
    .reduce<Position[]>((accumulator, id) => {
      const position = entities[id];

      if (!position) return accumulator;

      return [...accumulator, position];
    }, [])
    .filter((position) => position.status === positionStatus);

  return result;
};

export const selectPendingPositions = createSelector(
  positionsIdsSelector,
  positionsEntitiesSelector,
  positionsCombiner('pending'),
);

export const selectFulfilledPositions = createSelector(
  positionsIdsSelector,
  positionsEntitiesSelector,
  positionsCombiner('fulfilled'),
);

export const selectNotifiedPositions = createSelector(
  positionsIdsSelector,
  positionsEntitiesSelector,
  positionsCombiner('notified'),
);

export const selectOrderPositions = createSelector(
  positionsIdsSelector,
  positionsEntitiesSelector,
  positionsCombiner('preorder'),
);

export default positions.reducer;
