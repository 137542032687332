import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { Location } from './types';
import { loadLocations } from './api';

const locationsAdapter = createEntityAdapter<Location>();

export const locations = createSlice({
  name: 'locations',
  initialState: locationsAdapter.getInitialState(),
  reducers: {},
  extraReducers: {
    [loadLocations.fulfilled.type]: (state, action) => {
      if (action.payload.locations) {
        locationsAdapter.upsertMany(state, action.payload.locations);
      }
    },
  },
});

export const { selectById: selectLocationById, selectAll: selectAllLocations } = locationsAdapter.getSelectors();

export default locations.reducer;
