import { useEffect } from 'react';

export enum GridMobileEventType {
  InstallationResumed = 'omg_mobile__installationResumed',
}

const useGridMobileEventListener = (eventType: GridMobileEventType, handler: (event: CustomEvent) => void) => {
  useEffect(() => {
    const eventHandler = handler as EventListener;
    window.addEventListener(eventType, eventHandler);

    return () => {
      window.removeEventListener(eventType, eventHandler);
    };
  }, [eventType, handler]);
};

export default useGridMobileEventListener;
