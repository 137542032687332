import Axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';
import qs from 'query-string';
import {
  Queue,
  Position,
  Location,
  Occupancy,
  Notification,
  User,
  Station,
  PositionFeedback,
  Tag,
  Category,
} from './types';

const getTimezoneOffset = (): number => {
  if (process.env.NODE_ENV === 'test') {
    return 0;
  }

  const timeZoneOffset = new Date().getTimezoneOffset();

  return timeZoneOffset;
};

let baseApiUrlBase = '';
switch (window.location.hostname) {
  case 'localhost':
    baseApiUrlBase = 'http://localhost:7071/api';
    break;
  case 'queue-qa.omborigrid.com':
  case 'queue.qa.ombori.com':
    baseApiUrlBase = 'https://api-qa.omborigrid.com/regions/qa/queues/v1';
    break;
  case 'queue-in.omborigrid.com':
  case 'queue.in.ombori.com':
    baseApiUrlBase = 'https://api.omborigrid.com/regions/in/queues/v1';
    break;
  case 'queue-eu.omborigrid.com':
  case 'queue.eu.ombori.com':
    baseApiUrlBase = 'https://api.omborigrid.com/regions/eu/queues/v1';
    break;
  case 'queue-au.omborigrid.com':
  case 'queue.au.ombori.com':
    baseApiUrlBase = 'https://api.omborigrid.com/regions/au/queues/v1';
    break;
  case 'queue-uae.omborigrid.com':
  case 'queue.uae.ombori.com':
  case 'booking.ikea.com.bh':
  case 'booking.ikea.com.sa':
    baseApiUrlBase = 'https://api.omborigrid.com/regions/uae/queues/v1';
    break;
  case 'queue-us.omborigrid.com':
  case 'queue.us.ombori.com':
    baseApiUrlBase = 'https://api.omborigrid.com/regions/us/queues/v1';
    break;
  case 'queue-dev.griddeveloper.com':
  case 'queue.dev.ombori.com':
  default:
    baseApiUrlBase = 'https://api.griddeveloper.com/regions/dev/queues/v1';
    break;
}
export const baseApiUrl = baseApiUrlBase;

export const getRawBaseApiUrl = () => {
  const result = baseApiUrl.replace('/api', '');

  return result;
};

const axiosInstance = Axios.create({
  baseURL: baseApiUrl,
});

axiosInstance.interceptors.request.use((configOld) => {
  const token = localStorage.getItem('grid-queue-app-token');
  const internalId = localStorage.getItem('grid-queue-app-internal-id');
  const config = { ...configOld };
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  if (internalId && !config.headers['X-Authorization-Internal-Id']) {
    config.headers['X-Authorization-Internal-Id'] = internalId;
  }

  return config;
});

export const axios = axiosInstance;

export interface QueueOrgParams {
  organization: string;
  queue: string;
}

export interface QueueOrgPositionParams {
  organization: string;
  queue: string;
  position: string;
  sessionId?: string;
}

export interface OrgPositionSessionParams {
  organization: string;
  sessionId: string;
  position?: string;
}

export interface OrgPositionParams {
  organization: string;
  position: string;
}

export interface UserOrgParams {
  organization: string;
}

export interface AuthenticatedParams extends QueueOrgParams {
  pin?: string;
  token?: string | null;
  station?: Station | null;
}

interface PositionAuthenticatedParams extends AuthenticatedParams {
  position: string;
}
export const positionSchema = new schema.Entity<Position>('positions');

export const normalizePosition = (data: any) =>
  normalize<
    any,
    {
      positions: { [key: string]: Position };
    }
  >(data, positionSchema);

export const positionListSchema = new schema.Array(positionSchema);

export const normalizePositionList = (data: any) =>
  normalize<
    any,
    {
      positions: { [key: string]: Position };
    }
  >(data, positionListSchema);

export const queueSchema = new schema.Entity<Queue>('queues', {
  positions: [positionSchema],
});

export const normalizeQueue = (data: any) =>
  normalize<
    any,
    {
      positions?: { [key: string]: Position };
      queues: { [key: string]: Queue };
    }
  >(data, queueSchema);

export const occupancySchema = new schema.Entity<Occupancy>('occupancies');

export const normalizeOccupancy = (data: any) =>
  normalize<
    any,
    {
      occupancies: { [key: string]: Occupancy };
    }
  >(data, occupancySchema);

export const locationEntity = new schema.Entity<Location>('locations');

export const locationListSchema = new schema.Array(locationEntity);

interface QueuesList {
  categories: Category[];
  stations: Station[];
  id: string;
  title: string;
  displayTitle: {
    [key: string]: string;
  };
  emailsAllowed: string[];
}

const normalizeLocationList = (data: any) =>
  normalize<
    any,
    {
      locations: { [key: string]: Location };
    }
  >(data, locationListSchema);

export const queuesSchema = new schema.Entity<QueuesList>(
  'queues',
  {},
  {
    processStrategy: (value) => ({
      id: value.id,
      title: value.title,
      displayTitle: value.displayTitle,
      categories: value.categories,
      stations: value.stations,
      emailsAllowed: value.emailsAllowed,
    }),
  },
);

export const queuesListSchema = new schema.Array(queuesSchema);

const normalizeQueuesList = (data: Queue[]) =>
  normalize<
    any,
    {
      queues: Partial<Queue>[];
    }
  >(data, queuesListSchema);

export const userSchema = new schema.Entity<User>('users');
const normalizeUser = (data: any) =>
  normalize<
    any,
    {
      users: { [key: string]: User };
    }
  >(data, userSchema);

const loadQueuePrefixType = 'queues/loadQueue';
export const loadQueue = createAsyncThunk(
  loadQueuePrefixType,
  async ({ organization, queue, pin }: AuthenticatedParams) => {
    const { data } = await axios.get(
      `/organizations/${organization}/queues/${queue}?${qs.stringify({
        pin,
      })}`,
    );
    return normalizeQueue(data).entities;
  },
);

export const setQueueOpen = createAsyncThunk(
  'queues/setQueueOpen',
  async ({ organization, queue, pin, open }: AuthenticatedParams & { open: boolean }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `organizations/${organization}/queues/${queue}/toggle-open?${qs.stringify({
          pin,
        })}`,
        {
          open,
        },
      );
      return normalizeQueue(data).entities;
    } catch (error) {
      if (error.response) {
        return rejectWithValue({
          status: error.response.status,
          statusText: error.response.statusText,
        });
      }
      throw error;
    }
  },
);

export const setUserAvailable = createAsyncThunk(
  'users/setAvailable',
  async ({ organization, isAvailable }: { organization: string; isAvailable: boolean }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`organizations/${organization}/users/availablity`, {
        isAvailable,
      });
      return normalizeUser(data);
    } catch (error) {
      if (error.response) {
        return rejectWithValue({
          ...error.response,
        });
      }
      throw error;
    }
  },
);

export const clearQueue = createAsyncThunk(
  'queues/clearQueue',
  async ({ organization, queue, pin }: AuthenticatedParams, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `/organizations/${organization}/queues/${queue}/clear?${qs.stringify({
          pin,
        })}`,
      );
      return normalizeQueue(data).entities;
    } catch (error) {
      if (error.response) {
        return rejectWithValue({
          status: error.response.status,
          statusText: error.response.statusText,
        });
      }
      throw error;
    }
  },
);

export const clearOccupancy = createAsyncThunk(
  'queues/clearOccupancy',
  async ({ organization, queue, pin }: AuthenticatedParams, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `/organizations/${organization}/queues/${queue}/clear-occupancy?${qs.stringify({
          pin,
        })}`,
      );
      return normalizeQueue(data).entities;
    } catch (error) {
      if (error.response) {
        return rejectWithValue({
          status: error.response.status,
          statusText: error.response.statusText,
        });
      }
      throw error;
    }
  },
);

export const createPosition = createAsyncThunk(
  'positions/createPosition',
  async ({
    organization,
    queue,
    position,
    registrationEnabled = false,
  }: QueueOrgParams & { position: Partial<Position>; registrationEnabled?: boolean }) => {
    const { data } = await axios.post(
      `organizations/${organization}/queues/${queue}/positions?sendConfirmation=${position.type === 'manual'}`,
      {
        ...position,
        registrationEnabled,
        timeZoneOffset: getTimezoneOffset(),
      },
    );
    return normalizePosition(data).entities;
  },
);

export const createDigitalCallPosition = createAsyncThunk(
  'positions/createDigitalCallPosition',
  async ({ organization, body }: QueueOrgParams & { body: any }) => {
    const { data } = await axios.post(`organizations/${organization}/positions/call`, body);
    return normalizePosition(data).entities;
  },
);

export const updateNotificationForPosition = createAsyncThunk(
  'positions/updateNotificationForPosition',
  async ({ organization, queue, position, notification }: QueueOrgPositionParams & { notification: Notification }) => {
    const { data } = await axios.post(
      `organizations/${organization}/queues/${queue}/positions/${position}/update-position-notification`,
      {
        notification,
      },
    );
    return normalizePosition(data).entities;
  },
);

export const updateTagsForPosition = createAsyncThunk(
  'positions/updateTagsForPosition',
  async ({ organization, queue, position, tags }: QueueOrgPositionParams & { tags: string[] }) => {
    const { data } = await axios.post(
      `organizations/${organization}/queues/${queue}/positions/${position}/update-position-tags`,
      {
        tags,
      },
    );
    return normalizePosition(data).entities;
  },
);

export type BookingPosition = Position & Required<Pick<Position, 'notification' | 'booking'>>;
type CurbsidePickupPosition = Position & Required<Pick<Position, 'externalId' | 'notification' | 'booking'>>;

export const createBooking = createAsyncThunk(
  'positions/createBooking',
  async ({ organization, queue, position }: QueueOrgParams & { position: BookingPosition }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`organizations/${organization}/queues/${queue}/bookings`, {
        ...position,
        type: 'booking',
        status: 'draft',
        priority: 100,
        label: '',
        timeZoneOffset: getTimezoneOffset(),
      });
      return normalizePosition(data).entities;
    } catch (error) {
      if (error.response) {
        return rejectWithValue({
          errorMessage: error.response.data.message,
        });
      }
      throw error;
    }
  },
);

export const assignManagerForBooking = createAsyncThunk(
  'positions/assignManagerForBooking',
  async ({ organization, queue, position, email }: QueueOrgPositionParams & { email: string }) => {
    const { data } = await axios.post(
      `organizations/${organization}/queues/${queue}/positions/${position}/assign-booking-manager`,
      {
        email,
      },
    );
    return normalizePosition(data).entities;
  },
);

export const assignManagerForPosition = createAsyncThunk(
  'positions/assignManagerForPosition',
  async ({ organization, queue, position, email }: QueueOrgPositionParams & { email: string }) => {
    const { data } = await axios.post(
      `organizations/${organization}/queues/${queue}/positions/${position}/assign-manager`,
      {
        email,
      },
    );

    const result = normalizePosition(data).entities;

    return result;
  },
);

export const unAssignManagerFromPosition = createAsyncThunk(
  'positions/unAssignManagerFromPosition',
  async ({ organization, queue, position }: QueueOrgPositionParams) => {
    const { data } = await axios.post(
      `organizations/${organization}/queues/${queue}/positions/${position}/unassign-manager`,
    );

    const result = normalizePosition(data).entities;

    return result;
  },
);

export const cancelPositionByUser = createAsyncThunk(
  'position/cancelPositionByUser',
  async ({ organization, queue, position }: QueueOrgParams & { position: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.put(
        `organizations/${organization}/queues/${queue}/positions/${position}/cancel-position`,
        {},
      );
      return normalizePosition(data).entities;
    } catch (error) {
      if (error.response) {
        return rejectWithValue({
          status: error.response.status,
          statusText: error.response.statusText,
        });
      }
      throw error;
    }
  },
);

export const createCurbsidePickup = createAsyncThunk(
  'positions/createPickupTicket',
  async ({ organization, queue, position }: QueueOrgParams & { position: CurbsidePickupPosition }) => {
    const { data } = await axios.post(`organizations/${organization}/queues/${queue}/bookings`, {
      ...position,
      type: 'curbsidePickup',
      status: 'draft',
      priority: 0,
      label: '',
      timeZoneOffset: getTimezoneOffset(),
    });
    return normalizePosition(data).entities;
  },
);

export const loadPosition = createAsyncThunk(
  'positions/loadPosition',
  async ({ organization, queue, position }: QueueOrgPositionParams) => {
    const { data } = await axios.get(`/organizations/${organization}/queues/${queue}/positions/${position}`);
    return normalizePosition(data).entities;
  },
);

interface NextPositionProps extends AuthenticatedParams {
  userCategories?: string[];
}

export const nextPosition = createAsyncThunk(
  'positions/nextPosition',
  async ({ organization, queue, pin, station, userCategories }: NextPositionProps, { rejectWithValue }) => {
    const query = qs.stringify({ pin });

    try {
      const { data } = await axios.post(`organizations/${organization}/queues/${queue}/next?${query}`, {
        station,
        userCategories,
      });
      return normalizePosition(data).entities;
    } catch (error) {
      if (error.response) {
        return rejectWithValue({
          status: error.response.status,
          statusText: error.response.statusText,
          positions: [],
        });
      }
      throw error;
    }
  },
);

export const checkInPosition = createAsyncThunk(
  'positions/checkInPosition',
  async ({ organization, queue, pin, position }: AuthenticatedParams & { position: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `organizations/${organization}/queues/${queue}/positions/${position}/check-in?${qs.stringify({
          pin,
        })}`,
      );
      return normalizePosition(data).entities;
    } catch (error) {
      if (error.response) {
        return rejectWithValue({
          status: error.response.status,
          statusText: error.response.statusText,
          positions: [],
        });
      }
      throw error;
    }
  },
);

export const rejectPosition = createAsyncThunk(
  'positions/rejectPosition',
  async ({ organization, queue, pin, position }: AuthenticatedParams & { position: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `organizations/${organization}/queues/${queue}/positions/${position}/reject?${qs.stringify({
          pin,
        })}`,
      );
      return normalizePosition(data).entities;
    } catch (error) {
      if (error.response) {
        return rejectWithValue({
          status: error.response.status,
          statusText: error.response.statusText,
          positions: [],
        });
      }
      throw error;
    }
  },
);

export const retryPosition = createAsyncThunk(
  'positions/retryPosition',
  async ({ organization, queue, pin, position }: AuthenticatedParams & { position: string }) => {
    const { data } = await axios.post(
      `organizations/${organization}/queues/${queue}/positions/${position}/retry?${qs.stringify({
        pin,
      })}`,
    );
    return normalizePosition(data).entities;
  },
);

export const checkOutOldestPosition = createAsyncThunk(
  'positions/checkOutOldestPosition',
  async ({ organization, queue, pin }: AuthenticatedParams, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `organizations/${organization}/queues/${queue}/check-out?${qs.stringify({
          pin,
        })}`,
      );
      return normalizePosition(data).entities;
    } catch (error) {
      if (error.response) {
        return rejectWithValue({
          status: error.response.status,
          statusText: error.response.statusText,
        });
      }
      throw error;
    }
  },
);

export const updatePositionNumberOfPeople = createAsyncThunk(
  'positions/updatePositionNumberOfPeople',
  async ({ numberOfPeople, organization, queue, position }: QueueOrgPositionParams & { numberOfPeople: number }) => {
    const { data } = await axios.post(
      `/organizations/${organization}/queues/${queue}/positions/${position}/number-of-people`,
      {
        numberOfPeople,
      },
    );
    return normalizePosition(data).entities;
  },
);

export const loadLocations = createAsyncThunk(
  'locations/loadLocations',
  async ({ organization }: Pick<QueueOrgParams, 'organization'>) => {
    const { data } = await axios.get(`/organizations/${organization}/locations`);
    return normalizeLocationList(data).entities;
  },
);

const loadClickAndCollectPrefixType = 'queues/loadClickAndCollect';
export const loadClickAndCollect = createAsyncThunk(
  loadClickAndCollectPrefixType,
  async ({ organization, queue, pin }: AuthenticatedParams) => {
    const { data } = await axios.get(
      `/organizations/${organization}/queues/${queue}/click-and-collect?${qs.stringify({
        pin,
      })}`,
    );
    return normalizePositionList(data).entities;
  },
);

export const createPass = createAsyncThunk(
  'passes/createPass',
  async ({ organization, queue, locale }: QueueOrgParams & { locale: string }) => {
    const { data } = await axios.post<Position>(`organizations/${organization}/queues/${queue}/passes`, {
      locale,
    });
    return normalizePosition(data).entities;
  },
);

export const activatePosition = createAsyncThunk(
  'positions/activatePosition',
  async ({ organization, queue, position, activationNote }: QueueOrgPositionParams & { activationNote: string }) => {
    const { data } = await axios.post(`/organizations/${organization}/queues/${queue}/positions/${position}/activate`, {
      activationNote,
    });
    return normalizePosition(data).entities;
  },
);

export const completePosition = createAsyncThunk(
  'positions/completePosition',
  async ({ organization, queue, position }: QueueOrgPositionParams) => {
    const { data } = await axios.post(`/organizations/${organization}/queues/${queue}/positions/${position}/complete`);
    return normalizePosition(data).entities;
  },
);

export const cancelPositionByAdmin = createAsyncThunk(
  'positions/cancelPositionByAdmin',
  async ({ organization, queue, position, pin }: AuthenticatedParams & QueueOrgPositionParams) => {
    const { data } = await axios.post(
      `/organizations/${organization}/queues/${queue}/positions/${position}/admin-cancel`,
      undefined,
      {
        params: {
          pin,
        },
      },
    );
    return normalizePosition(data).entities;
  },
);

export const submitFeedbackForm = createAsyncThunk(
  'positions/submitFeedbackForm',
  async ({ organization, queue, position, feedback }: QueueOrgPositionParams & { feedback: PositionFeedback }) => {
    const { data } = await axios.post(
      `organizations/${organization}/queues/${queue}/positions/${position}/update-position-feedback`,
      {
        feedback,
      },
    );
    return normalizePosition(data).entities;
  },
);

export const subscribeClickAndCollectNotifications = createAsyncThunk(
  'queues/subscribeClickAndCollectNotifications',
  async ({ organization, queue, pin, registrationToken }: AuthenticatedParams & { registrationToken: string }) => {
    await axios.post(
      `/organizations/${organization}/queues/${queue}/click-and-collect/subscribe?${qs.stringify({
        pin,
      })}`,
      {
        registrationToken,
      },
    );
  },
);

interface LoadPositionProps extends AuthenticatedParams {
  isReplaceExisting?: boolean;
}

const loadPositionsPrefixType = 'queues/loadPositions';
export const loadPositions = createAsyncThunk(
  loadPositionsPrefixType,
  async ({ organization, queue, pin, isReplaceExisting = false }: LoadPositionProps) => {
    const { data } = await axios.get(
      `/organizations/${organization}/queues/${queue}/positions?${qs.stringify({
        pin,
      })}`,
    );

    return { data: normalizePositionList(data).entities, isReplaceExisting };
  },
);

export const loadPositionsByExternalId = createAsyncThunk(
  'queues/loadPositionsByExternalId',
  async ({ organization, queue, externalId }: QueueOrgParams & { externalId: string }) => {
    const { data } = await axios.get(
      `/organizations/${organization}/queues/${queue}/positions-by-externalId?externalId=${externalId}`,
    );
    return normalizePositionList(data).entities;
  },
);

export const loadOrganizationPositionsByExternalId = createAsyncThunk(
  'queues/loadOrganizationPositionsByExternalId',
  async ({ organization, externalId }: { organization: string; externalId: string }) => {
    const { data } = await axios.get(`/organizations/${organization}/positions-by-externalId?externalId=${externalId}`);
    return normalizePositionList(data).entities;
  },
);

const loadOccupancyPrefixType = 'occupancies/loadOccupancy';
export const loadOccupancy = createAsyncThunk(
  loadOccupancyPrefixType,
  async ({ organization, queue }: QueueOrgParams) => {
    const { data } = await axios.get(`/organizations/${organization}/queues/${queue}/occupancy`);
    return normalizeOccupancy(data).entities;
  },
);

export const updateOccupancy = createAsyncThunk(
  'occupancies/updateOccupancy',
  async ({
    organization,
    queue,
    pin,
    peopleIn = 0,
    peopleOut = 0,
    manual = true,
  }: AuthenticatedParams & { peopleIn?: number; peopleOut?: number; manual?: boolean }) => {
    const { data } = await axios.post(
      `/organizations/${organization}/queues/${queue}/occupancy?${qs.stringify({
        pin,
        manual,
      })}`,
      {
        peopleIn,
        peopleOut,
      },
    );
    return normalizeOccupancy(data).entities;
  },
);

export const updateQueueSettings = createAsyncThunk(
  'queues/updateQueueSettings',
  async ({ organization, queue, pin, storeCapacity }: AuthenticatedParams & { storeCapacity: number }) => {
    const { data } = await axios.post(
      `/organizations/${organization}/queues/${queue}/settings?${qs.stringify({
        pin,
      })}`,
      {
        storeCapacity,
      },
    );
    return normalizeQueue(data).entities;
  },
);

export const callPosition = createAsyncThunk(
  'positions/callPosition',
  async (
    { organization, queue, pin, position, station }: AuthenticatedParams & { position: string },
    { rejectWithValue },
  ) => {
    const query = qs.stringify({ pin });

    try {
      const { data } = await axios.post(
        `organizations/${organization}/queues/${queue}/positions/${position}/call?${query}`,
        {
          station,
        },
      );
      return normalizePosition(data).entities;
    } catch (error) {
      if (error.response) {
        return rejectWithValue({
          status: error.response.status,
          statusText: error.response.statusText,
          positions: [],
        });
      }
      throw error;
    }
  },
);

export const checkOutPosition = createAsyncThunk(
  'positions/checkOutPosition',
  async ({ organization, queue, pin, position }: AuthenticatedParams & { position: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `organizations/${organization}/queues/${queue}/positions/${position}/check-out?${qs.stringify({
          pin,
        })}`,
      );
      return normalizePosition(data).entities;
    } catch (error) {
      if (error.response) {
        return rejectWithValue({
          status: error.response.status,
          statusText: error.response.statusText,
          positions: [],
        });
      }
      throw error;
    }
  },
);

export const acceptCall = createAsyncThunk(
  'positions/acceptCall',
  async (
    { organization, queue, pin, position, internalId }: PositionAuthenticatedParams & { internalId?: string },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await axios.post(
        `organizations/${organization}/queues/${queue}/positions/${position}/accept-call?${qs.stringify({
          pin,
        })}`,
        {},
        {
          headers: internalId ? { 'X-Authorization-Internal-Id': `${internalId}` } : {},
        },
      );
      return data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue({
          status: error.response.status,
          statusText: error.response.statusText,
          positions: [],
        });
      }
      throw error;
    }
  },
);

export const loadUser = createAsyncThunk('users/loadUser', async ({ organization }: { organization: string }) => {
  const { data } = await axios.get(`/organizations/${organization}/me`);
  return normalizeUser(data);
});

export const pingUser = createAsyncThunk(
  'users/pingUser',
  async ({ organization, queue }: { organization: string; queue: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/organizations/${organization}/users/ping`, { queueId: queue });
      return data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue({
          status: error.response.status,
          statusText: error.response.statusText,
        });
      }
      throw error;
    }
  },
);

export const updateUserTags = createAsyncThunk(
  'users/updateTags',
  async ({ organization, userId, tags }: { organization: string; userId: string; tags: Tag[] }) => {
    const { data } = await axios.post(`/organizations/${organization}/users/${userId}/tags`, {
      tags,
    });

    return normalizeUser(data);
  },
);

export const updateUserCategories = createAsyncThunk(
  'users/updateCategories',
  async ({ organization, userId, categories }: { organization: string; userId: string; categories: string[] }) => {
    const { data } = await axios.post(`/organizations/${organization}/users/${userId}/categories`, {
      categories,
    });

    return normalizeUser(data);
  },
);

export const loadDigitalCallPosition = createAsyncThunk(
  'positions/loadDigitalCallPosition',
  async ({ organization, position }: OrgPositionParams) => {
    const { data } = await axios.get(`/organizations/${organization}/digital-call/${position}`);
    return normalizePosition(data).entities;
  },
);

export const fetchTimeSlots = async (organization: string, queue: string, minDate?: string, maxDate?: string) =>
  axios.get(`/organizations/${organization}/queues/${queue}/timeslots`, {
    params: {
      minDate,
      maxDate,
    },
  });

export const verifyUser = (organization: string, queue: string, internalId?: string | null) => {
  return axios.post(
    `/organizations/${organization}/queues/${queue}/verify-user`,
    {},
    {
      headers: internalId ? { 'X-Authorization-Internal-Id': `${internalId}` } : {},
    },
  );
};

export const verifyUserInternalId = (organization: string, queue: string, internalId: string) =>
  axios.post(
    `organizations/${organization}/queues/${queue}/verify-user-internal-id`,
    {},
    {
      headers: { 'X-Authorization-Internal-Id': `${internalId}` },
    },
  );

export const fetchQueue = async ({ organization, queue }: QueueOrgParams): Promise<Queue> => {
  const { data } = await axios.get(`/organizations/${organization}/queues/${queue}`);

  return data;
};

export const removeDigitalCallPositionFromStore = createAsyncThunk(
  'positions/removeDigitalCallPosition',
  async (position: Position) => {
    return normalizePosition(position).entities;
  },
);

export const loadPositionBySession = createAsyncThunk(
  'positions/loadPositionBySession',
  async ({ organization, sessionId }: OrgPositionSessionParams) => {
    const { data } = await axios.get(
      `/organizations/${organization}/positions/active-by-sessionId?sessionId=${sessionId}`,
    );
    if (!data || !data.length) {
      return {};
    }
    return normalizePosition(data[0]).entities;
  },
);

export const verifyPin = (organization: string, queue: string, pin: string) =>
  axios.post(`organizations/${organization}/queues/${queue}/verify-pin`, pin, {
    headers: { 'Content-Type': 'text/plain' },
  });

interface NotifyAboutClickAndCollectOrderProps {
  organization: string;
  queue: string;
  orderLink: string;
  phoneNumber: string;
  locale: string;
}

export const notifyAboutClickAndCollectOrder = ({
  organization,
  queue,
  orderLink,
  phoneNumber,
  locale,
}: NotifyAboutClickAndCollectOrderProps): Promise<void> =>
  axios.post(`organizations/${organization}/queues/${queue}/click-and-collect/notify`, {
    orderLink,
    phoneNumber,
    locale,
  });

interface CreateClickAndCollectOrderProps extends QueueOrgParams {
  minDate: number;
  maxDate: number;
  orderId: string;
  phoneNumber: string;
  note?: string;
}

interface TransferPositionsPayload {
  positions: string[];
  queue?: string;
  category?: string;
  station?: string;
  agent?: string;
}

export const createClickAndCollectOrder = createAsyncThunk(
  'positions/createClickAndCollectOrder',
  async ({ organization, queue, minDate, maxDate, orderId, phoneNumber, note }: CreateClickAndCollectOrderProps) => {
    const { data } = await axios.post(`organizations/${organization}/queues/${queue}/click-and-collect/orders`, {
      minDate,
      maxDate,
      orderId,
      phoneNumber,
      note,
    });

    return normalizePosition(data).entities;
  },
);

export const getClickAndCollectOrders = createAsyncThunk(
  'positions/getClickAndCollectOrders',
  async ({ organization, queue }: QueueOrgParams) => {
    const { data } = await axios.get(`/organizations/${organization}/queues/${queue}/click-and-collect/orders`);

    return normalizePositionList(data).entities;
  },
);

export const listOrganizationQueues = createAsyncThunk(
  'organization/loadQueues',
  async ({ organization }: Pick<QueueOrgParams, 'organization'>) => {
    const { data } = await axios.get(`/organizations/${organization}/queues`);
    return normalizeQueuesList(data).entities;
  },
);

export const transferPositions = createAsyncThunk(
  'queues/transfer-positions',
  async (
    {
      payload: { positions, category, station, agent, queue },
      params,
    }: {
      payload: TransferPositionsPayload;
      params: QueueOrgParams;
    },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await axios.post(
        `/organizations/${params.organization}/queues/${params.queue}/positions-transfer`,
        {
          queue,
          positions,
          category,
          station,
          agent,
        },
      );
      return normalizePosition(data).entities;
    } catch (error) {
      if (error.response) {
        return rejectWithValue({
          status: error.response.status,
          statusText: error.response.statusText,
          positions: [],
        });
      }
      throw error;
    }
  },
);
