import * as firebase from 'firebase/app';
import 'firebase/messaging';
import * as Sentry from '@sentry/browser';

const initFirebase = () => {
  try {
    firebase.initializeApp({
      apiKey: 'AIzaSyCIfIpnWStH2bajR7eTToxyCaPPbdgyR1Q',
      projectId: 'ombori-queue-service',
      messagingSenderId: '901992047000',
      appId: '1:901992047000:web:0cdfe022113c6a0a25cbed',
    });

    if (firebase.messaging.isSupported()) {
      const messaging = firebase.messaging();
      messaging.usePublicVapidKey(
        'BEjvOb9R-vL4XR3sF4Iji6at2nTGYmTpi4GXWYIhoXEllGn-qp1vH-rTyx5UtUPY7-dN5hrvJ6DCN4Sy7w7qcXs',
      );

      // handle foreground notifications
      messaging.onMessage((payload) => {
        navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope').then((registration) => {
          if (registration) {
            registration.showNotification(payload.notification.title, payload.notification);
          }
        });
      });
    }
  } catch (err) {
    Sentry.captureException(err);
  }
};

export default initFirebase;
