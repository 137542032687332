import { UseQueryResult, useQuery } from 'react-query';
import * as Sentry from '@sentry/browser';
import { useParams } from 'react-router-dom';
import { Organization } from '../store/types';
import { axios } from '../store/api';

const fetchOrganization = async (organizationName: string): Promise<Organization> => {
  try {
    const response = await axios.get<Organization>(`/organizations/${organizationName}`);

    return response.data;
  } catch (error) {
    Sentry.captureException(error);

    throw error;
  }
};

const useOrganization = (): UseQueryResult<Organization, unknown> => {
  const { organization: organizationName } = useParams<{ organization: string }>();

  return useQuery(['organization', organizationName], () => fetchOrganization(organizationName), {
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
  });
};

export default useOrganization;
