import React, { lazy, useCallback, useEffect, useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { i18n } from 'i18next';
import 'dayjs/locale/ar';
import { CacheProvider } from '@emotion/core';
import { Helmet } from 'react-helmet';
import styled from '@emotion/styled';
import { ThemeProvider } from '@material-ui/core/styles';
import initI18n from '../../i18n';
import { Organization as OrganizationType } from '../../store/types';
import useOrganization from '../../common/use-organization';
import useDayjsLocale from './useDayjsLocale';
import useEmotionCache from './useEmotionCache';
import useMuiTheme from './useMuiTheme';

const Queue = lazy(() => import('../routes/route-queue'));
const QueueList = lazy(() => import('../queue-list'));
const LocationSelection = lazy(() => import('../location-selection'));
const DigitalCall = lazy(() => import('../routes/route-digital-call'));

interface OrganizationProps {
  organization: OrganizationType;
}

const defaultSupportedLocales = ['en', 'sv'];

const Organization: React.FC<OrganizationProps> = ({ organization }) => {
  const [i18nInt, setI18n] = useState<i18n | null>(null);
  const { path }: { path: string } = useRouteMatch();

  const organizationSupportedLocales = organization.supportedLocales;

  const initLocales = useCallback(async () => {
    if (i18nInt) return;

    const supportedLocales = organizationSupportedLocales ?? defaultSupportedLocales;

    const i18nGlobalInstance = await initI18n(supportedLocales);

    setI18n(i18nGlobalInstance);
  }, [i18nInt, organizationSupportedLocales]);

  useEffect(() => {
    initLocales();
  }, [initLocales]);

  const language = i18nInt?.language ?? 'en';

  const dir = i18nInt?.dir() ?? 'ltr';

  const emotionCache = useEmotionCache(dir);

  const theme = useMuiTheme(dir);

  useDayjsLocale(language);

  if (!i18nInt) {
    return null;
  }

  return (
    <CacheProvider value={emotionCache}>
      <ThemeProvider theme={theme}>
        <Helmet>
          <html dir={dir} lang={language} />
          {organization?.siteTitle && <title>{organization.siteTitle}</title>}
          {organization?.favicon && <link rel="icon" href={organization?.favicon} />}
        </Helmet>
        <Switch>
          <Route exact path={`${path}/locations`} component={LocationSelection} />
          <Route path={`${path}/digital-call`} render={() => <DigitalCall organization={organization} />} />
          <Route path={`${path}/queues/:queue`} component={Queue} />
          <Route path={`${path}/queue-list`} component={QueueList} />
        </Switch>
      </ThemeProvider>
    </CacheProvider>
  );
};

const Title = styled.h1`
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  padding: 16px;
  color: #fff;
  background-color: #000;
`;

const ErrorTitle = styled(Title)`
  color: #e74c3c;
`;

const OrganizationContainer: React.FC = () => {
  const organization = useOrganization();

  return (
    <>
      {organization.isLoading && <Title>Loading organization</Title>}

      {organization.isSuccess && <Organization organization={organization.data} />}

      {organization.isError && (
        <ErrorTitle>An error occurred while loading the organization. Please, try again.</ErrorTitle>
      )}
    </>
  );
};

export default OrganizationContainer;
