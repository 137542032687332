import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { Queue } from './types';
import { clearOccupancy, clearQueue, loadQueue, setQueueOpen, updateQueueSettings } from './api';

const queuesAdapter = createEntityAdapter<Queue>();

export const queues = createSlice({
  name: 'queues',
  initialState: queuesAdapter.getInitialState(),
  reducers: {},
  extraReducers: {
    [loadQueue.fulfilled.type]: (state, action) => {
      if (action.payload.queues) {
        queuesAdapter.upsertMany(state, action.payload.queues);
      }
    },
    [updateQueueSettings.fulfilled.type]: (state, action) => {
      queuesAdapter.upsertMany(state, action.payload.queues);
    },
    [setQueueOpen.pending.type]: (state, action) => {
      queuesAdapter.updateOne(state, {
        id: action.meta.arg.queue,
        changes: {
          open: action.meta.arg.open,
        },
      });
    },
    [setQueueOpen.fulfilled.type]: (state, action) => {
      queuesAdapter.upsertMany(state, action.payload.queues);
    },
    [clearQueue.fulfilled.type]: (state, action) => {
      queuesAdapter.upsertMany(state, action.payload.queues);
    },
    [clearOccupancy.fulfilled.type]: (state, action) => {
      queuesAdapter.upsertMany(state, action.payload.queues);
    },
  },
});

export const { selectById: selectQueueById } = queuesAdapter.getSelectors();

export default queues.reducer;
