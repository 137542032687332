import { createMuiTheme, Theme } from '@material-ui/core/styles';
import { useMemo } from 'react';

const useMuiTheme = (dir: 'rtl' | 'ltr'): Theme => {
  const theme = useMemo(() => {
    return createMuiTheme({
      direction: dir,
    });
  }, [dir]);

  return theme;
};

export default useMuiTheme;
