import { createSlice, createSelector } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { AppState } from '.';
import { loadUser, setUserAvailable, updateUserTags, updateUserCategories } from './api';
import { User } from './types';

interface IUserAdapter {
  user: User | undefined;
}

const initialState: IUserAdapter = {
  user: undefined,
};

const userSelector = (state: AppState) => ({ users: state.users });
export const selectUser: (state: AppState) => User | undefined = createSelector(userSelector, ({ users }) => {
  return users.user;
});

export const users = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: {
    [loadUser.fulfilled.type]: (state, action) => {
      if (action.payload.entities.users) {
        const userId = Object.keys(action.payload.entities.users)[0];
        // we need to think about returning state instead of updating it directly
        // eslint-disable-next-line no-param-reassign
        state.user = action.payload.entities.users[userId];
      }
    },
    [setUserAvailable.fulfilled.type]: (state, action) => {
      if (action.payload.entities.users) {
        const userId = Object.keys(action.payload.entities.users)[0];
        // eslint-disable-next-line no-param-reassign
        state.user = action.payload.entities.users[userId];
      }
    },
    [updateUserTags.fulfilled.type]: (state, action) => {
      if (action.payload.entities.users) {
        const userId = Object.keys(action.payload.entities.users)[0];
        // eslint-disable-next-line no-param-reassign
        state.user = action.payload.entities.users[userId];
      }
    },
    [updateUserCategories.fulfilled.type]: (state, action) => {
      if (action.payload.entities.users) {
        const userId = Object.keys(action.payload.entities.users)[0];
        // eslint-disable-next-line no-param-reassign
        state.user = action.payload.entities.users[userId];
      }
    },
  },
});

export default users.reducer;
