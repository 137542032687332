import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { loadOccupancy, updateOccupancy } from './api';
import { Occupancy } from './types';

const occupanciesAdapter = createEntityAdapter<Occupancy>();

export const occupancies = createSlice({
  name: 'occupancies',
  initialState: occupanciesAdapter.getInitialState(),
  reducers: {},
  extraReducers: {
    [loadOccupancy.fulfilled.type]: (state, action) => {
      if (action.payload.occupancies) {
        occupanciesAdapter.upsertMany(state, action.payload.occupancies);
      }
    },
    [updateOccupancy.fulfilled.type]: (state, action) => {
      if (action.payload.occupancies) {
        occupanciesAdapter.upsertMany(state, action.payload.occupancies);
      }
    },
  },
});

export const { selectById: selectOccupancyById, selectAll: selectAllOccupancies } = occupanciesAdapter.getSelectors();

export default occupancies.reducer;
