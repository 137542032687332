import createCache, { EmotionCache } from '@emotion/cache';
import { useMemo } from 'react';
// @ts-ignore
import stylisRtl from 'stylis-rtl';

const useEmotionCache = (dir: string): EmotionCache => {
  const emotionCache = useMemo(() => {
    return createCache({ stylisPlugins: dir === 'rtl' ? [stylisRtl] : [] });
  }, [dir]);

  return emotionCache;
};

export default useEmotionCache;
