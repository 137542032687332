import { useEffect } from 'react';
import dayjs from 'dayjs';

const loadLocale = (lng: string) => import(`dayjs/locale/${lng}.js`);

const useDayjsLocale = (language: string): void => {
  useEffect(() => {
    const lng = language.substring(0, 2);

    loadLocale(lng).then(() => {
      dayjs.locale(lng);
    });
  }, [language]);
};

export default useDayjsLocale;
